import React from "react";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChairOffice, faUser } from "@fortawesome/pro-regular-svg-icons";
import { useLocalization } from "gatsby-theme-i18n";
import _ from "lodash";
import styled from "@emotion/styled";
import boardOfDirectors from "../../../../cms/boardOfDirectors/boardOfDirectors";
import InterestedItems from "../../../../components/InterestedItems";
import { headers, restHeaders } from "../../../../utils/navbar";
import governanceDocs from "../../../../cms/governanceDocs/governanceDocs";
import Container from "../../../../components/Container";
import NewLayout from "../../../../components/NewLayout";
import DocumentList from "../../../../components/DocumentList";
import DetailsGridTeam from "../../../../components/DetailsGridTeam";
import ArrowButton from "../../../../components/ArrowButton";

function GovernanceBankPage() {
  const interestedItems = [
    { ..._.get(headers(), "investors.elements.investors_about") },
    { ..._.get(restHeaders(), "about.elements.about_team") },
    {
      ..._.get(
        headers(),
        "investors.elements.financial_information_reports.elements.bank_reports",
      ),
    },
  ];
  const { locale } = useLocalization();
  const docs = governanceDocs[locale].filter(
    ({ attributes: { company } }) => company === "Allfunds_Bank",
  );
  const documents = [
    {
      title: "Governance Documents",
      items: docs.filter(
        ({ attributes: { category } }) => category === "Governance_Documents",
      ),
    },
    // {
    //   title: "Compliance and AML",
    //   items: docs.filter(
    //     ({ attributes: { category } }) => category === "Compliance_And_AML",
    //   ),
    // },
  ];
  const members = boardOfDirectors[locale].filter(
    ({
      attributes: {
        companies: { data },
      },
    }) => data.map(({ attributes }) => attributes.name).includes("Bod - AFBank"),
  );
  const memberSections = _.groupBy(
    members.filter(({ attributes: { section } }) => section),
    "attributes.section",
  );
  const sections = Object.keys(memberSections);

  return (
    <NewLayout byDefault apps="investors.governance.allfunds_bank">
      <Container pb={8}>
        <Typography mt={3} variant="h2">
          Allfunds Bank, S.A.U.
        </Typography>
        <Typography mt={2} mb={1} fontWeight={700}>
          Corporate Governance and Remuneration Policy
        </Typography>
        <Typography mb={2}>
          Allfunds Group plc (Allfunds) is the parent company of the group. It
          is a UK-based company listed on Euronext Amsterdam. Allfunds is the
          sole indirect shareholder of Allfunds Bank, the Spanish company that
          holds the banking licence and operates as a regulated financial
          institution. Although each company has its own governing bodies and
          internal rules, the governance of their boards of directors is
          consistent, well-integrated and aligned through an adequate and
          efficient flow of information, access for both of them to all data and
          information necessary to understand and lead the group and a
          coordinated schedule of meetings.
        </Typography>
        <Box>
          <Typography variant="h3" mt={3}>
            Board of Directors
          </Typography>
          <DetailsGridTeam items={members} />
        </Box>
        <Box my={10}>
          <Typography variant="h3" mt={3}>
            Board Committees
          </Typography>
          <Stack mb={3} direction="row" spacing={4} justifyContent="end">
            <Stack spacing={1} direction="row" alignItems="center">
              <StyledIcon icon={faUser} />
              <Typography>Member</Typography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <StyledIcon icon={faChairOffice} />
              <Typography>Chair</Typography>
            </Stack>
          </Stack>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography variant="h5">Board of directors</Typography>
                </TableCell>
                {sections.map((header) => (
                  <TableCell align="center">
                    <Typography variant="h5">
                      {header.replaceAll("_", " ")}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map(
                (
                  { attributes: { full_name, section, position, role } },
                  index,
                ) => (
                  <TableRow>
                    <TableCell align="center">
                      <Typography variant="small">
                        {full_name}
                        {index === members.length - 1 &&
                          ` (${position.replaceAll(/[()]/g, "")})`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {section === sections[0] ? (
                        <StyledIcon
                          icon={role === "Chair" ? faChairOffice : faUser}
                        />
                      ) : index === members.length - 1 ? (
                        position
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {section === sections[1] ? (
                        <StyledIcon
                          icon={role === "Member" ? faUser : faChairOffice}
                        />
                      ) : index === members.length - 1 ? (
                        position
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </Box>
        {documents.map(({ title, items }) => (
          <DocumentList mb={5} title={title} items={items} direction="row" />
        ))}
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography>Other corporate policies and statements</Typography>
          <ArrowButton isDark path="/esg/policies-statements" />
        </Stack>
        <InterestedItems items={interestedItems} />
      </Container>
    </NewLayout>
  );
}

const StyledIcon = styled(FontAwesomeIcon)`
  width: 18px;
  height: 18px;
`;

export default GovernanceBankPage;
